import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import axios from 'axios';

import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput
  } from 'mdb-react-ui-kit';

import Fab from '@mui/material/Fab';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CallEndIcon from '@mui/icons-material/CallEnd';

const Room = () => {
    const location = useLocation();
    const userVideo = useRef();
    const userStream = useRef();
    const remoteVideoRef = useRef(null);
    const remoteAudioRef = useRef(null);
    const peerRef = useRef();
    const webSocketRef = useRef();
    const [triggeredAlready, setTriggered] = useState(false)
    const isConnected = useRef()
    const [showControls, setShowControls] = useState(false)

    const [isMicMuted, setMicMuted] = useState(true)

    
    const navigate = useNavigate()

    const checkConnection = () => {
        return showControls
    }

    const handleTrack = (event) => {
        const track = event.track;
        console.log(event)
        if (track.kind === 'video') {
            if (event.transceiver.mid == 0) {
                remoteVideoRef.current.srcObject = new MediaStream([track]);
            } else {
                //TODO: Add in tester webcam
            }
        } else if (track.kind === 'audio') {
            remoteAudioRef.current.srcObject = new MediaStream([track]);
        }
    };
  const sendTURNCredRequest = async (email) => {
    const apiURL = "https://topmod-backend.daqa.net/api/v1/collab/getAuth"
    // const apiURL = "http://localhost:80/api/v1/collab/getAuth"
    let creds = {};

    try {
      // Create the request payload
      const payload = {
        email: email
      };
  
      // Send the HTTP request
      const response = await axios.post(apiURL, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        const responseData = response.data;
        creds.username = responseData.username;
        creds.password = responseData.password;
        return creds;
      } else {
        throw new Error(`Invalid Request With Status: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }
    

    const openCamera = async () => {
        const constraints = {
            video: false,
            audio: true,

        };
        await navigator.mediaDevices.getUserMedia(constraints).then((stream) =>{
            if (userVideo.current != null) {
                userVideo.current.srcObject = stream
                userStream.current = stream
            }
        })
    };

    const MessageHandler = async (e) => {
        console.log(e.data)
        const message = JSON.parse(e.data);
        
        if (message.error != undefined) {
            webSocketRef.current = null
            console.log(location.pathname)
            if (location.pathname != "/room/test") {
                navigate("/", { state: { error: "Room does not exist." } })
            }
        }

        if (message.join) {
            callUser();
        }

    
        if (isConnected.current === true) {
            if (message.type == "disconnect") {
                disconnectCall()
            }
            if (message.type == "offer" && message.message.length > 0) {
                    handleOffer(message.message);
            }


            // if (message.type == "answer") {
            //     console.log("Receiving Answer");
            //     peerRef.current.setRemoteDescription(
            //         new RTCSessionDescription(message.message)
            //     );
            // }
        }

        if (message.type == "candidate") {
            console.log("Receiving and Adding ICE Candidate");
            console.log(message.message)
        try{
            await peerRef.current.addIceCandidate(
                message.message
        );
        }catch(err) {
                console.log("error ICE CANDIDADE")
        }
            
            
        }
        
    }

    useEffect(() => {
        if (location.pathname != "/room/test") {
            if (!triggeredAlready) {
                setTriggered(true)
                isConnected.current = false
                openCamera().then( async () => {
                    const roomID = location.pathname.split("/");
                    webSocketRef.current = await new WebSocket(`wss://turn.daqa.net:21338/?host=false&room=${roomID[2]}`)

                    webSocketRef.current.onopen = async (event) => {
                        console.log("Connecting...")
                        if (webSocketRef.current.readyState == 1) {
                            console.log("Ready!")
                            isConnected.current = true
                            setShowControls(true)
                            createPeer().then(async (peer) => {
                                peerRef.current = peer
                                console.log("Created peer connection, sending Join")
                                // handleNegotiationNeeded()
                                if (webSocketRef.current != null) {
                                    await webSocketRef.current.send(
                                        JSON.stringify({ sender: "BroadCast", type: "join", message: "Init" })
                                    )
                                }
                            });
                        }
                    };

                    // createPeer().then(() => {
                    //     handleNegotiationNeeded()
                    // });

                    await webSocketRef.current.addEventListener("message", MessageHandler)
                    setTimeout(() => {
                        muteMic()
                    }, 2500)
            
                })
            }
        }
    }, []);

    const handleOffer = async (offer) => {
        console.log("Received Offer, Creating Answer");
        console.log(offer)
        if (peerRef.current) {
            console.log("Already have peer")
            await peerRef.current.setRemoteDescription(
                new RTCSessionDescription({type: "offer", sdp: offer})
            );
    
            await userStream.current.getTracks().forEach((track) => {
                peerRef.current.addTrack(track, userStream.current);
            });
    
            const answer = await peerRef.current.createAnswer();
            await peerRef.current.setLocalDescription(answer);
    
            await webSocketRef.current.send(
                JSON.stringify({ sender: "BroadCast", type: "answer", message: peerRef.current.localDescription.sdp })
            );
        } else {
            createPeer().then(async (peer) => {
                peerRef.current = peer
                await peerRef.current.setRemoteDescription(
                    new RTCSessionDescription({type: "offer", "sdp": offer})
                );
        
                await userStream.current.getTracks().forEach((track) => {
                    peerRef.current.addTrack(track, userStream.current);
                });
        
                const answer = await peerRef.current.createAnswer();
                await peerRef.current.setLocalDescription(answer);
        
                await webSocketRef.current.send(
                    JSON.stringify({ sender: "BroadCast", type: "answer", message: peerRef.current.localDescription.sdp })
                );
            })
        }
        
    };

    const callUser = async () => {
        console.log("Calling Other User");
        peerRef.current = createPeer();

        await userStream.current.getTracks().forEach(async (track) => {
            await peerRef.current.addTrack(track, userStream.current)
            isConnected.current = true
        });
    };

    const createPeer = () => {
        return sendTURNCredRequest("client").then((creds)=> {
            console.log("Creating Peer Connection");
            console.log(creds)
            const peer = new RTCPeerConnection({
                iceServers: [{ 
                    urls: "turn:turn.daqa.net:5349?transport=udp",
                    username: creds.username,
                    credential: creds.password
                 }],
                 iceTransportPolicy: "relay",
            });
    
            peer.onnegotiationneeded = handleNegotiationNeeded;
            peer.addTransceiver("video")
            peer.onicecandidate = handleIceCandidateEvent;
            peer.ontrack = handleTrack;
    
            return peer;
        })
    };

    const handleNegotiationNeeded = async () => {
        console.log("Creating Offer - needs negotiation");

        try {
                peerRef.current.createOffer().then(async (myOffer) => {
                await peerRef.current.setLocalDescription(myOffer);

                await webSocketRef.current.send(
                    JSON.stringify({ sender: "BroadCast", type: "join", message: peerRef.current.localDescription.sdp })
                );
            });
        } catch (err) {
            console.log(err)
        }
        
    };

    const handleIceCandidateEvent = async (e) => {
        if (e.candidate) {
            console.log("Found Ice Candidate");
            console.log(e.candidate);
            await webSocketRef.current.send(
                JSON.stringify({ sender: "BroadCast", type: "candidate", message: e.candidate })
            );
        }
    };

    const disconnectCall = async () => {
        await webSocketRef.current.send(
            JSON.stringify({ sender: "BroadCast", type: "disconnect" })
        );
        webSocketRef.current.removeEventListener("message", MessageHandler)
        webSocketRef.current = null
        peerRef.current = null
        userVideo.current.srcObject.getTracks().forEach(function(track) {
            track.stop();
          });
        userVideo.current = null
        userStream.current = null
        navigate("/", { state: { error: "Connection Closed." } })
    }

    const muteMic = () => {
        if (peerRef.current != undefined) {
            if (userVideo.current.srcObject.getAudioTracks().length > 0) {
                console.log("Toggled microphone")
                setMicMuted(!userVideo.current.srcObject.getAudioTracks()[0].enabled)
                userVideo.current.srcObject.getAudioTracks()[0].enabled = !userVideo.current.srcObject.getAudioTracks()[0].enabled
            }
        }
    }

    const handleTrackEvent = (e) => {
        console.log("Received Tracks");
        console.log(e.streams)
        window.peer = peerRef
        // window.partnerVid = partnerVideo
        // partnerVideo.current.srcObject = e.streams[0]
        // partnerVideoStream.current = e.streams[0]
        // peerRef.current.getTracks().forEach( 
        //     (track) =>  {
        //       ColabPeerRef.current.addTrack( track, ColabUserVideoStream.current );
        //     }
        //   );
        // peerRef.current.addTrack(e.streams[0])
        // partnerVideo.current.srcObject = e.streams;
    };

    
  return (
    <MDBContainer className="gradient-form">
        <video className='tester-vid' ref={remoteVideoRef} autoPlay playsInline />
        <MDBRow>
            <MDBCol md='4' className="col-md-4 mb-5">

            </MDBCol>
            <MDBCol md='4' className="col-md-4 mb-5">
                <div className="d-flex flex-column">
                    <div className="logo mt-12 transparent"></div>                   
                    <audio className='hidden' playsInline autoPlay muted controls={true} ref={userVideo} />
                    
                    <audio className='hidden' ref={remoteAudioRef} autoPlay playsInline />
                </div>
            </MDBCol>
            {
                checkConnection() ?
                <MDBCol md='4' className="col-md-4 mb-5">
                    <div className='control-bar'>
                        <Fab disabled="true" color="primary" aria-label="add" onClick={() => {muteMic()}}>
                            {
                                isMicMuted ?
                                    <MicIcon />
                                :
                                    <MicOffIcon />
                            }
                        </Fab>
                        <Fab color="secondary" aria-label="edit" onClick={() => { disconnectCall()}}>
                            <CallEndIcon />
                        </Fab>
                        {/* <Fab variant="extended">
                            <NavigationIcon sx={{ mr: 1 }} /> Navigate
                        </Fab>
                        <Fab disabled aria-label="like">
                            <FavoriteIcon />
                        </Fab> */}
                    </div>
                </MDBCol>
            : null
            }

        </MDBRow>

    </MDBContainer>
  )
}

export default Room;